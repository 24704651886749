.change-password {
  .sign-in-page {
    .password-field {
      &__info {
        @include breakpoint($medium-up) {
          bottom: 35px;
        }
      }
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__rules {
          li {
            display: flex;
          }
          &--popup {
            column-count: 2;
          }
        }
      }
    }
  }
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($medium-up) {
        margin-top: 35px;
      }
    }
  }
}
