.pc_hidden {
  display: block;
  @include breakpoint($medium-up) {
    display: none;
  }
}

.checkout {
  .cart-item {
    .selectBox {
      height: 32px;
      line-height: 28px;
      min-width: 3.5em;
      padding-right: 30px;
      @include breakpoint($medium-down) {
        min-width: 3em;
        padding-left: 5px;
      }
      &::-ms-expand {
        display: none;
      }
    }
  }
  .shipping-additional {
    .invoice_container,
    .gift-options {
      float: left;
      width: 50%;
    }
    .giftwrap {
      text-align: start;
    }
  }
  .adpl {
    .title,
    .address-to-use,
    .state_select,
    .city_select,
    .district_select,
    .default-shipping {
      label {
        display: inline;
      }
    }
    input[type='email'],
    input[type='tel'],
    input[type='password'],
    input[type='text'],
    select {
      font-size: 20px;
      height: calc(3em - 4px);
    }
  }
  #sign-in-panel {
    .sign-in-page {
      width: 50%;
      margin-left: 40px;
      .sms-otp-link-container {
        float: left;
      }
      .sms-otp {
        &__item {
          padding: 0.5em 0;
          input[type='text'] + label {
            height: 3em;
            line-height: 3em;
          }
          .btn-move-up {
            margin-top: -0.1em;
            width: 67px;
            height: 48px;
          }
          .loading {
            top: auto;
            left: auto;
            opacity: unset;
            margin-left: 10px;
          }
          .btn--full {
            width: 100%;
          }
        }
        &__fieldset {
          input[name='MOBILE_NUMBER'] {
            display: inline;
            width: calc(100% - 77px);
          }
          input[name='PIN_CODE'] {
            width: 100%;
          }
        }
      }
      .return-user {
        width: 100%;
        &__item {
          padding: 0.5em 0;
          .btn--full {
            width: 100%;
          }
        }
        padding: 0px;
        &__fieldset {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
      input[type='submit'] {
        color: $color-white;
        background-color: $color-drak-black;
        height: 56px;
      }
    }
    .checkout_signin_new_user_form,
    .checkout_signin_guest_user_form {
      position: relative;
      padding-bottom: 20%;
      .form-item.submit {
        width: 100%;
      }
    }
    .form-item.submit {
      bottom: 0;
    }
    .checkout {
      &__panel {
        &--heading {
          font-weight: bold;
        }
      }
    }
  }
  #sign-in-panel,
  #shipping-panel,
  #review-panel {
    .messages {
      #error_offer_bogo {
        display: none;
      }
    }
  }
  #shipping-panel {
    .checkout {
      &__panel {
        &--heading {
          font-weight: bold;
        }
      }
    }
    #invoice-change-email-wrapper {
      .field-container {
        float: left;
        .btn--full {
          height: 56px;
          width: 60px;
          color: $color-white;
          background-color: $color-drak-black;
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }
  }
  .birthday-container {
    select {
      min-width: initial;
      width: 30%;
      margin-right: 1.5%;
      display: inline;
    }
  }
  .email_promotions {
    padding: 0 20px;
  }
  #payment-container {
    #payment_tab li {
      margin: 0;
      width: 49.5%;
      float: left;
      border: 1px solid $color-light-gray;
      border-bottom-color: $color-green;
      padding: 0.8em 0;
      text-align: center;
      list-style-type: none;
      &.selected {
        border-color: $color-green;
        border-bottom: none;
      }
    }
    #selected_box {
      clear: both;
      border: 1px solid $color-green;
      border-top: none;
      width: 99%;
      padding: 4% 4% 2%;
    }
    .payt_left,
    .payt_right {
      display: inline-block;
      vertical-align: top;
    }
    .payt_left {
      width: 52%;
    }
    .btn_rad {
      display: inline-block;
      margin: 15px 3px 0 -2px;
      vertical-align: top;
      input[type='radio']:checked ~ label:before,
      input[type='radio'] ~ label:before {
        vertical-align: top;
      }
    }
    .btn_rad:nth-child(3) {
      margin-bottom: -50px;
      margin-right: -17px;
      label:nth-child(1) {
        margin-bottom: 4%;
      }
    }
    .divider_gray_line {
      margin: 10px 0;
      border-top: 1px solid $color-light-gray;
    }
    label {
      vertical-align: top;
      display: inline-block;
    }
    .sms_bind_mobile {
      margin-top: 25px;
      &::before {
        margin-bottom: 15px;
      }
    }
    input[type='radio'] {
      margin: 40px 5px 0;
      vertical-align: top;
      display: block;
    }
    .use_pc {
      display: inline-block;
      max-width: 175px;
    }
    .use_img {
      display: inline-block;
      max-width: 120px;
    }
    h5 {
      margin: 5px 0;
    }
    #banks {
      height: 250px;
      margin-bottom: 10px;
      margin-left: 5px;
      li {
        min-height: 40px;
        float: left;
        margin: 0 5px 10px 0;
        width: 24.32%;
      }
      img {
        border: 1px solid $color-light-gray;
        vertical-align: top;
      }
      label {
        margin-bottom: 0;
      }
    }
    .float-right {
      margin: 0 2%;
    }
  }
  #samples-panel {
    header {
      h2 {
        width: auto;
      }
    }
    .samples-panel {
      &__content {
        width: auto;
      }
    }
    .product-list .product {
      .skus li.sku {
        width: auto;
      }
    }
    ul.product-list {
      .product {
        min-height: 200px;
      }
    }
    .details {
      padding-right: 10px;
      word-wrap: break-word;
      margin-left: 2px;
      text-align: center;
    }
    .product-size {
      text-align: center;
    }
  }
  &__sidebar {
    .offer-emp,
    .order-summary-empty {
      border-top: none;
      padding: 0;
    }
  }
  &__content {
    .cart-items__item--qty {
      @include breakpoint($medium-down) {
        margin-left: 0;
      }
      select {
        min-width: 3.5em;
      }
    }
    #shipping-panel {
      .gift-options-display {
        margin: -209px 0 0 340px;
      }
      .delivery-options-display {
        margin-top: 100px;
      }
      .invoice_options_display {
        margin: -77px 0 0 295px;
      }
      .newsletters_options_display {
        margin: -71px 0 0 490px;
      }
      .select-address {
        .address-to-use {
          margin: 0.8em 0;
        }
        .select-menu {
          padding-right: 1.33333em;
        }
      }
    }
  }
  &-confirmation-page {
    .sign-in-confirmation-panel__content {
      .form-item {
        margin-bottom: 1em;
      }
    }
  }
  &__cod-packaging-notes {
    float: left;
    width: 66.6666667%;
    border-bottom: none;
    max-width: 800px;
    margin-bottom: 25px;
  }
  &__panel.collapsed {
    .checkout {
      &__panel--heading {
        display: none;
      }
    }
  }
  #viewcart-panel {
    .checkout {
      &__panel--header {
        height: 36px;
        padding: 0 2em;
      }
      &__panel--heading {
        display: inline;
      }
    }
    #samples-panel {
      .checkout {
        &__panel {
          &--header {
            border-bottom: none;
            padding: 0;
            .margin_top_bottom {
              border-bottom: none;
            }
            .remaining {
              p.samples-count-desc {
                padding: 0 0 0 20px;
              }
            }
          }
          &--heading {
            display: block;
          }
        }
      }
      .mobile_header {
        display: none;
      }
    }
  }
  .no-replenishment {
    .cart-items {
      &__header--qty {
        text-align: left;
      }
      &__item--product-name {
        margin-bottom: 5px;
      }
    }
  }
  #recommended-products-panel {
    .recommended-item {
      &__product-info {
        text-align: left;
        padding: 20px 0px;
        &__subname {
          &.regional_subname {
            font-size: 21px;
            line-height: 1.2;
          }
        }
        &__name {
          &__link {
            font-family: $english_font;
          }
        }
      }
      &__cta {
        text-align: left;
        display: table-cell;
        .button {
          border: none;
        }
      }
      &__product-image {
        position: relative;
        text-align: left;
        &__badge--text {
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 15px;
          z-index: 1;
        }
        &__image {
          position: relative;
          &--alt {
            width: auto;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity 0.3s;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .product-brief {
        &__benefit {
          text-align: left;
          font-size: 15px;
        }
        &__spp-link {
          display: table-cell;
          padding-left: 10px;
          font-size: 15px;
        }
      }
    }
  }
}

.order-details-page {
  .order-products {
    &__header--price {
      text-align: center;
    }
    &__header--qty {
      text-align: center;
    }
    &__header--total {
      text-align: center;
    }
    .account-products {
      &__item {
        .product__qty {
          p {
            font-size: 16px;
            line-height: 2.2;
          }
        }
        &.sample {
          .product {
            &__price,
            &__qty {
              font-weight: normal;
              letter-spacing: 0px;
              font-size: 16px;
              line-height: 2.2;
            }
          }
        }
      }
    }
  }
  .order-totals {
    td {
      padding: 0.5em 0.5em 0.5em 5em;
      p.est_delivery {
        line-height: 0;
        margin: 0;
      }
    }
  }
}

#colorbox {
  #overlay_wcppay_qr {
    text-align: center;
  }
  #overlay_alipay_qr {
    text-align: center;
  }
}

body {
  &.order-confirmation {
    .order-details-page {
      .account-utilities {
        display: none;
      }
      .order-details-page {
        &__content {
          margin-left: 90px;
        }
      }
    }
  }
}

.samples-panel__content {
  ul.skus {
    input[type='checkbox'] {
      position: relative;
      left: 0px;
    }
  }
  ul.product-list {
    .selected {
      border: none !important;
    }
    .hover {
      border: none !important;
    }
    .product {
      border: none !important;
    }
  }
  .product-list-container {
    .product-brief__subtitle {
      font-size: 17px !important;
    }
  }
}

.add-samples .addsamplestocart {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font--futura-demi;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
  line-height: 1;
  padding-top: 2px;
  line-height: 42px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $color-black;
  color: $color-white;
  text-align: center;
  text-decoration: none;
  border: solid 1px $color-white;
  -moz-box-shadow: 0 0 0 1px $color-black;
  -webkit-box-shadow: 0 0 0 1px $color-black;
  box-shadow: 0 0 0 1px $color-black;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 1px;
  font-size: 24px;
}

.add-samples {
  text-align: center;
}

.checkout #promo-panel .messages .single-message {
  color: $color-red;
}

.checkout__content {
  #order-summary-panel {
    display: none;
  }
}

.opc__shipping {
  .checkout__panel--shipping__edit-address {
    .address-form {
      .form_element.title {
        span {
          display: inline-block;
        }
      }
    }
  }
}

.adpl {
  input {
    &[type='email'] + label:before,
    &[type='tel'] + label:before,
    &[type='password'] + label:before,
    &[type='text'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  select + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

.viewcart {
  .samples-panel {
    ul {
      &.product-list {
        padding: 40px 20px 0;
      }
    }
  }
}
