.product-grid-wrapper {
  .product-grid__item {
    .product-brief__review-snippet {
      @include breakpoint($medium-down) {
        float: right;
        width: 57.664%;
        .pr-review-snippet-container {
          text-align: left;
        }
      }
    }
  }
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-reco-stars {
          @include breakpoint($medium-down) {
            float: none;
          }
        }
      }
    }
    @include breakpoint($medium-down) {
      margin: 0px;
    }
  }
}
/* Power Review Page chinese fonts */

/* Mixin function for pr label, span display */
@mixin pr-label {
  font-family: $chinese_font_global;
}
/* Mixin function for pr link */
@mixin pr-link {
  font-family: $chinese_font_global;
}
/* Mixin function for pr header */
@mixin pr-headline {
  font-family: $font--subheading;
}
/* Mixin function for pr description */
@mixin pr-desc {
  font-family: $chinese_font_global;
}
/* Mixin function for pr button */
@mixin pr-button {
  @include button-basic();
  font-family: $chinese_font_global;
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
#pr-reviewsnippet,
.product-full__info,
.product-grid--quickshop,
#pr-questiondisplay {
  .p-w-r {
    a {
      @include pr-link();
    }
    label {
      @include pr-label();
    }
    select {
      &.form-control {
        @include pr-select;
      }
    }
    .pr {
      &-clear-all-radios {
        @include pr-link();
      }
      &-accessible-btn {
        @include pr-button();
      }
      &-header-table {
        .pr-header-title {
          @include pr-headline();
        }
      }
      &-snippet-read-and-write {
        &:before {
          margin: 0;
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          font-family: $chinese_font_global;
        }
        .pr-rd-description-text {
          @include pr-desc();
        }
        .pr-rd-footer {
          font-family: $chinese_font_global;
          .pr-rd-helpful-action {
            .pr-helpful-count {
              font-family: $chinese_font_global;
            }
          }
        }
        .pr-rd-pagination {
          &-btn {
            font-family: $font--cta;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @include pr-headline();
      }
      .pr-text {
        @include pr-desc();
        span {
          @include pr-desc();
        }
      }
      .pr-attribute-header {
        @include breakpoint($medium-down) {
          font-family: $chinese_font_global;
        }
      }
    }
  }
  /* For Question and answer Section */
}

#pr-war-form {
  span {
    @include pr-headline();
  }
  input {
    font-family: $chinese_font_global;
  }
}

.write_a_review__container {
  .pr-subscript {
    font-family: $chinese_font_global;
  }
}
/* Spp Page Review Container */
.spp__container {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-snippet-standard {
        padding: 0 0 0 15%;
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-compact,
      .pr-snippet-minimal {
        .pr-snippet-reco-to-friend-green {
          .pr-reco-value {
            font-family: $chinese_font_global;
          }
        }
      }
      .pr-accordion-btn {
        span {
          font-family: $chinese_font_global;
        }
      }
      .pr-review-snapshot {
        a.pr-snippet-write-review-link {
          @include pr-button();
          position: absolute;
          top: 82px;
          right: 20px;
          @include breakpoint($medium-down) {
            top: 150px;
            right: 13px;
          }
        }
      }
      .pr-snippet-reco-to-friend {
        .pr-reco-to-friend-message {
          font-family: $chinese_font_global;
        }
        span {
          @include breakpoint($medium-down) {
            font-family: $chinese_font_global;
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-faceoff {
        &-readcomplete {
          @include pr-link();
        }
      }
    }
    .pr-review-display {
      .pr-rd-review-headline {
        @include pr-headline();
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort {
          option {
            @include pr-headline();
          }
        }
        .pr-rd-main-footer {
          .pr-rd-review-position {
            .pr-rd-bold {
              font-family: $chinese_font_global;
            }
          }
          .pr-rd-to-top {
            font-family: $font--cta;
          }
        }
      }
      &.pr-rd-display-desktop,
      &.pr-rd-display-tablet {
        .pr-rd-description {
          .pr-rd-side-content-block {
            font-family: $chinese_font_global;
          }
          .pr-rd-author-nickname {
            @include pr-headline();
          }
        }
        .pr-rd-helpful-text {
          line-height: 0.65em;
        }
      }
      .pr-rd-content-block {
        @include breakpoint($medium-down) {
          font-family: $chinese_font_global;
        }
      }
      .pr-modal-content {
        .pr-flag-review-btn {
          @include pr-button();
        }
        .pr-flag-review {
          span {
            font-family: $chinese_font_global;
          }
        }
        .pr-control-label {
          @include pr-label();
        }
      }
      .pr-rd-sort {
        @include breakpoint($medium-down) {
          font-family: $chinese_font_global;
        }
      }
    }
    .pr-review-snapshot-snippets {
      .pr-snippet-stars-reco-stars {
        .pr-snippet {
          &:after {
            font-family: $chinese_font_global;
          }
        }
      }
    }
    .pr-helpful {
      &-yes {
        .pr-helpful-count {
          &:before {
            content: '有 ';
            visibility: visible;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            content: '没有 ';
            visibility: visible;
          }
        }
      }
    }
  }
  .prfieldwrap {
    font-family: $chinese_font_global;
  }
}
/* Question and Answer */

#pr-questionsnippet {
  .pr-answered-question-circle {
    @include pr-link();
  }
}

#pr-questiondisplay {
  .p-w-r {
    .pr-qa-display {
      .pr-qa-display-ask-question {
        button.pr-btn-ask-question {
          @include pr-button();
          &:hover {
            @include pr-button();
          }
        }
        .btn-group {
          font-family: $chinese_font_global;
        }
        .pr-waq-legal-text {
          font-family: $chinese_font_global;
        }
        .pr-helper-text {
          font-family: $chinese_font_global;
        }
      }
      .pr-qa-sort {
        &-headline {
          font-family: $chinese_font_global;
        }
        &-group {
          font-family: $chinese_font_global;
        }
      }
      .pr-qa-display {
        &-answer-by,
        &-answer-text {
          @include pr-desc();
        }
        &-item,
        &-item-details {
          h2 {
            @include pr-headline();
          }
        }
        &-item-details {
          font-family: $chinese_font_global;
        }
        &-search-no-results {
          font-family: $chinese_font_global;
        }
      }
      .pr-btn-answer {
        @include pr-link();
        &:hover {
          .pr-qa-display-text {
            @include pr-link();
          }
        }
      }
      .pr-btn-show-more {
        .pr-qa-display-text {
          @include pr-link();
        }
      }
    }
    abbr[title] {
      font-family: $chinese_font_global;
    }
    .pr-helpful {
      &-count {
        font-family: $chinese_font_global;
      }
    }
    .pr-waq-prequestion-message {
      h3 {
        font-family: $chinese_font_global;
      }
      .prfieldwrap {
        font-family: $chinese_font_global;
      }
    }
  }
  .p-w-r {
    .pr-qa-display {
      .pr-qa-display-search {
        .pr-qa-display-searchbar {
          input {
            font-family: $chinese_font_global;
          }
        }
      }
    }
  }
}

.spp-page {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        span {
          font-size: 14px !important;
        }
        .pr-snippet-write-review-link {
          font-family: $font--cta;
        }
      }
    }
    .pr-qa-snippet-container {
      padding-top: 10px;
      .pr-ask-question-link {
        &:before {
          content: ' ';
          padding-right: 0;
        }
      }
      .pr-snippet-qa-metrics {
        margin: 0;
        &:after {
          content: '\007C';
          padding: 0 10px 0 15px;
        }
      }
    }
  }
  .product-full {
    .p-w-r {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          font-family: $chinese_font_global;
        }
        .pr-snippet-write-review-link {
          &:before {
            content: '\007C';
          }
        }
      }
    }
  }
}

.spp_reviews {
  .p-w-r {
    #pr-image-display {
      display: none;
    }
  }
}

.expando-block {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        span {
          font-size: 12px !important;
        }
      }
    }
  }
}

.product-grid-wrapper {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          margin-top: 0px;
          vertical-align: middle;
        }
      }
    }
  }
}

#power_review_container {
  .product-full__details {
    h1 {
      @include breakpoint($medium-down) {
        font-size: 24px;
      }
    }
  }
  #pr-write {
    .p-w-r {
      .table-cell {
        @include breakpoint($medium-down) {
          padding: 0;
        }
      }
      .pr-header-table {
        .pr-header-title {
          @include breakpoint($medium-down) {
            font-size: 24px;
          }
        }
      }
      .form-group {
        select {
          font-size: 16px;
        }
      }
      #pr-war-form {
        .pr-media_videourl-form-group,
        .pr-media_image-form-group {
          display: block;
          input + label {
            background-color: $color-dark-gray;
            border-color: $color-white;
            span {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
