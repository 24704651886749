$base-theme-path: '../../or_base/';

@import 'overrides/overrides';
@import '../../or_base/scss/main';
@import 'cn_fonts.scss';
@import 'sections/_gwp_pc';
@import 'sections/account-reset-password';
@import 'sections/recommended_section';

// SMACSS/sections/account
@import 'sections/pc/account/account';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';

// SMACSS/sections/all
@import 'sections/pc/all';

// Power Review files from origins base
@import 'sections/power_review/_power_review.scss';

// Cookie Settings.
@import '../../or_base/scss/sections/cookie_settings_ot';
@import 'sections/one_trust';
@import 'sections/_all';

// Legal consent
@import 'sections/_legal_consent';

.invoice-edit-electronic {
  font-size: small;
}

@media (min-width: 769px) {
  .product-full__carousel__thumbs {
    margin-top: 0px !important;
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .product-full__carousel__slides {
    display: block !important;
  }
}

.product-full__carousel__thumb {
  display: inline-block !important;
  margin-right: 3%;
}

.product-brief {
  .p-w-r {
    display: none !important;
  }
}
.product-full.product-full-v2 {
  .product-full__anchor-link {
    display: none;
  }
}

.collapsed {
  display: none;
}
