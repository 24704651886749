.page-footer {
  &__primary {
    .social_wechat {
      margin: 0;
    }
    .footer-formatter-primary {
      @include breakpoint($large-up) {
        &__content {
          text-align: justify;
          display: block;
          &:after {
            content: '';
            width: 100%;
            display: inline-block;
          }
        }
        &__section {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
}

.product-social {
  .qrWrapper {
    display: none;
    z-index: 999;
    position: absolute;
    padding: 20px;
    right: 145px;
    cursor: pointer;
    background-color: $color-white;
    box-shadow: 0 0 3px grey;
  }
  .product-social__icon {
    cursor: pointer;
    &:last-of-type {
      margin: 0;
    }
  }
}

.qrcodeTableHidden {
  display: none;
  position: absolute;
  padding: 20px;
  bottom: 25px;
  z-index: 999;
  cursor: pointer;
  background-color: $color-white;
  box-shadow: 0 0 3px grey;
  width: 225px;
  margin-top: 375px;
  top: 10px;
  height: 280px;
  h3 {
    font-size: 14px;
  }
}

.qrcodeTable {
  h3 {
    font-size: 17px;
  }
}

.menu__link--lvl-2 {
  #footer-wechat-qrcode {
    display: none;
    z-index: 999;
    position: absolute;
    background-color: $color-white;
    box-shadow: 0 0 3px grey;
    right: 140px;
    margin-top: 10px;
  }
}

input[type='checkbox'] {
  ~ label,
  ~ .label {
    padding-top: 5px;
  }
}

.page-header {
  .site-email-signup {
    .site-email-signup__fields {
      input[name='MOBILE_PHONE'] {
        display: none;
      }
      label {
        display: none;
      }
    }
  }
  .contextual-links-region {
    float: left;
  }
  &__meta-nav {
    &__inner {
      .gnav-offers-block {
        &__headline {
          font-size: 16px;
        }
        &__list {
          &__item {
            a {
              &.gnav-offers-block {
                &__offer {
                  &--link {
                    font-size: 16px;
                    font-family: $chinese_font_global;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__utils {
    .menu {
      &__link {
        &--lvl-1 {
          font-size: 16px;
        }
      }
    }
  }
  &__nav {
    &__main {
      .menu__link {
        font-size: 20px;
        &--lvl-1 {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
    &__supplemental {
      .gnav_lyl {
        font-size: 14px;
        font-style: italic;
      }
    }
  }
  .page-header__utils {
    .menu--lvl-2 {
      .block-template-gnav-email-signup-ca-v1 {
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
  }
  .gnav-by-collection-item {
    &__desc {
      font-size: 14px;
    }
  }
  .page-header__top-bar {
    background-color: $color-black;
    .gnav-offers-formatter {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

.spp-page {
  .product-full__info {
    .product-offer {
      display: block;
    }
  }
  .product-offer .product-offer__text {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
  }
  .product-offer .product-offer__header {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
  }
  .full_sign_in {
    input.form-text {
      width: 250px;
      max-width: 100%;
      margin-top: 5px;
      display: block;
    }
  }
  .product-full.product-full-v2 {
    .product-social {
      .qrcodeTable {
        min-width: 180px;
      }
    }
    .product-full__subline {
      margin: 2% 0;
    }
  }
}

.product-quickshop {
  &__footer {
    &__price,
    &__actions {
      width: 22%;
    }
    &__add-to-bag {
      width: 34%;
    }
  }
}

.product-brief {
  &__ctas--desktop {
    display: table-cell;
    text-align: center;
    width: 22%;
  }
}

.gnav-util {
  .trustmark-logo-div img {
    width: 65px;
  }
}

.mpp {
  .product-brief {
    &__subline {
      height: 25px;
      margin: 21.06px 0;
    }
    &__ctas--desktop {
      display: block;
      width: 100%;
    }
  }
}

.icon--weibo {
  background: url(/media/images/global/weibo.jpg) no-repeat center;
  width: 20px;
  height: 17px;
}

.icon--wechat {
  background: url(/media/images/global/wechat.png) no-repeat center;
  width: 35px;
  height: 22px;
  &:before {
    display: none;
  }
}

.password-request-page {
  #form--password_reset_send--field--PC_VALIDATE_EMAIL_ADDRESS {
    margin-left: 10px;
    width: 281px;
  }
}

.customer-service-contact-form {
  &__radio-item,
  &__radio-item--use-products,
  &__radio-item--site-member {
    padding-left: 2px;
  }
}

#prechat_container {
  .prechat-form-container {
    .form-row {
      .form-col-one {
        width: 43%;
      }
      .form-col-two {
        width: 53%;
      }
    }
    .prechat-input {
      width: 100%;
    }
    .registered-input {
      overflow: hidden;
    }
  }
}

.page-header {
  &__nav,
  &__meta-nav {
    &__inner {
      padding: 0 10px;
    }
  }
}

.sticky-chat-content {
  .prechat-form-container {
    .registered-input {
      font-size: 19px;
      overflow: hidden;
    }
  }
}

.signin-block {
  &__authed {
    min-width: 470px;
  }
}

.page-branding__logo {
  margin-top: 0;
}

.checkout {
  .checkout__panel--content {
    .adpl {
      input[type='email'] + label,
      input[type='tel'] + label,
      input[type='password'] + label,
      input[type='text'] + label,
      select + label {
        /* margin-top: -webkit-calc(3em - 1px); */
      }
    }
  }
}

.store-locator__my-store-saved a {
  display: none;
}

.offer_bank_container {
  margin-left: 10px;
  margin-bottom: 15px;
  padding-bottom: 22px;
  border-bottom: 1px solid $color-light-gray;
  visibility: hidden;
}

.offer_bank_container input[type='radio'] {
  position: static;
  left: auto;
}

img.checkout__offer-code__wallet-item__image {
  width: 144px;
}

.checkout__offer-code__wallet-item {
  text-align: center;
}

.offer_bank_description {
  padding-top: 3%;
  padding-bottom: 3%;
}

.slick-prev:before {
  content: url(/media/images/OR_SPP_LEFT_ARROW3.png);
  right: 0px;
}

.slick-next:before {
  content: url(/media/images/OR_SPP_RIGHT_ARROW3.png);
  right: 0px;
}

button.slick-prev.slick-arrow {
  left: 65px;
  margin-top: 0;
  width: 25px;
  height: 25px;
  padding: 0;
  @include breakpoint($medium-up) {
    left: -80px;
  }
}

button.slick-next.slick-arrow {
  right: 65px;
  margin-top: 0;
  width: 25px;
  height: 25px;
  padding: 0;
  @include breakpoint($medium-up) {
    right: -80px;
  }
}

.checkout__offer-code__wallet-item__button-container {
  padding-bottom: 5px;
}

.cs-page {
  h1 {
    font-size: 2em;
  }
}

.page-header {
  &.alt-nav-mob {
    .page-header__utils {
      .utility-nav {
        @include breakpoint($landscape-up) {
          margin-top: 0;
        }
      }
    }
  }
}

.page-header__utils {
  .utility-nav__cart {
    &.gnav-util {
      margin-left: 15px;
    }
  }
}

.search-wrapper--pc {
  .trustmark-logo-div img {
    width: 65px;
  }
}
