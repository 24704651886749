.sign-in-page {
  .account-main-content {
    text-align: center;
    .button {
      width: 100%;
    }
    &__heading {
      width: 50%;
      padding: 28px;
      display: inline-block;
      .heading-welcome {
        text-align: center;
        font-size: 22px;
        letter-spacing: 1px;
        text-indent: 1px;
      }
      .heading-title {
        border-bottom: 1px solid $color-mid-gray;
        padding: 20px 5px 0px 0px;
        font-size: 20px;
        font-weight: 400;
        float: left;
        width: 100%;
        .new-account-link {
          padding: 0px 5px 2px 0px;
          float: left;
        }
        .return-user-link {
          padding: 0px 5px 2px 0px;
          float: right;
        }
        .title-link {
          border-bottom: 3px solid $color-drak-black;
        }
      }
    }
  }
  .account-page {
    &__section--heading {
      text-align: center;
      float: left;
      width: 50%;
    }
  }
  .return-user {
    width: 50%;
    display: none;
    #signin {
      .account-page {
        &__forgot-password {
          a#forgot-password {
            color: $color-black;
            font-size: 14px;
          }
          a#forgot-password:hover {
            color: $color-link-hover;
          }
        }
      }
    }
    .form-button {
      max-width: 664px !important;
    }
    &__fieldset {
      display: inline-block;
      text-align: center;
      .code-button {
        height: 48px;
        margin-left: 26px;
      }
    }
    &__item {
      width: 100% !important;
    }
  }
  .new-account {
    width: 50%;
    display: inline-block;
    &__item {
      width: 100%;
    }
    .mobile-item {
      width: 67%;
    }
    .form-item {
      label.label {
        padding-top: 0px;
      }
    }
    .form-button {
      clear: both;
    }
    &__fieldset {
      label {
        text-align: left;
      }
      .code-button {
        height: 48px;
        width: 30%;
        font-size: 15px;
        color: $color-white;
        background-color: $color-drak-black;
      }
    }
  }
  .return-user__fieldset {
    text-align: left;
  }
}

.adpl {
  .bd_phone {
    .sms-form {
      .sms_popup_code_container {
        .form-item {
          float: left;
          .margin_top_bottom {
            float: right;
            margin: 1em 3.6em 1em 0;
          }
        }
      }
    }
  }
  input[type='email'].error {
    border-color: $color-red;
  }
  fieldset {
    max-width: 664px;
  }
}

.birth_date_container {
  span {
    width: 31%;
    float: left;
    margin-right: 2%;
  }
}

.profile-info {
  &__fieldset {
    .full_name_container {
      width: 100%;
      float: none;
    }
    .sms_code_container {
      margin-top: 0.8em;
    }
    .grayout_dummy_email {
      color: $color-white;
    }
  }
}

.account-utilities {
  width: 19.667%;
  &__section {
    .sidebar-menu {
      &__item {
        .mobile_number {
          color: $color-dark-gray;
          transition: color 0.2s ease 0s;
        }
      }
    }
  }
}

.account-page {
  &__content {
    width: 80.333%;
  }
  &__section {
    fieldset {
      .sms_cod_account_bound {
        line-height: 3.3em;
        padding-bottom: 0;
      }
    }
  }
}

.privacy-policy {
  &__required {
    margin-right: 5px;
  }
}

.sms_popup {
  padding: 20px 20px 40px 20px;
  h3 {
    margin-bottom: 10px;
  }
  &_mobile_container {
    label {
      display: none;
    }
    .sms-cod {
      height: 52px;
    }
    .unbind_mobile_container {
      padding-top: 20px;
      label {
        display: inline-block;
      }
    }
  }
  &_code_container {
    .form-item {
      float: none;
    }
    .sms_code_short {
      display: none;
    }
    .sms_code_input {
      &.error {
        background: rgba(0, 0, 0, 0) url('/media/export/images/sms_code_icons/wrong_bg.jpg') no-repeat scroll right
          center;
      }
      &.success {
        background: rgba(0, 0, 0, 0) url('/media/export/images/sms_code_icons/right_bg.jpg') no-repeat scroll right
          center;
      }
    }
  }
  &_buttons {
    text-align: center;
    .bd_now {
      margin-right: 30px;
    }
  }
  .are_you_sure {
    margin: 10px 0;
  }
  #sms_error {
    margin-bottom: 10px;
  }
}

.orders-list {
  &__table {
    &--cell {
      &.order-date {
        @include breakpoint($small-up) {
          width: 22%;
        }
      }
      &.order-number {
        @include breakpoint($small-up) {
          width: 22%;
        }
      }
      &.order-tracking_number {
        @include breakpoint($small-up) {
          width: 25%;
        }
      }
    }
  }
}

.order-details-page {
  .track_detail {
    .date,
    .time {
      width: 120px;
    }
    .weekday {
      width: 60px;
    }
  }
  .order-products {
    &__header--product {
      width: 70%;
    }
  }
}

.checkout,
.account-page {
  &__content {
    .cart-items,
    .account-products {
      &__item--desc {
        width: 60%;
      }
    }
    .sms_code_input {
      &.error {
        background: rgba(0, 0, 0, 0) url('/media/export/images/sms_code_icons/wrong_bg.jpg') no-repeat scroll right
          center;
      }
      &.success {
        background: rgba(0, 0, 0, 0) url('/media/export/images/sms_code_icons/right_bg.jpg') no-repeat scroll right
          center;
      }
    }
    .change-birth-text {
      color: $color-red;
    }
  }
}

.ie {
  .sms_popup {
    &_code_container {
      .sms_code_input {
        &.error {
          background: none;
        }
        &.success {
          background: none;
        }
      }
    }
  }
  .checkout,
  .account-page {
    &__content {
      .sms_code_input {
        &.error {
          background: none;
        }
        &.success {
          background: none;
        }
      }
    }
  }
}

.profile-pic-upload {
  .upload-file {
    margin-bottom: 31px;
    &__button {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      position: relative;
      border: 1px solid $color-drak-black;
      height: 40px;
      padding: 0 20px;
      vertical-align: middle;
      line-height: 40px;
      margin-bottom: 0;
    }
    &__imposter {
      display: block;
      font-weight: bold;
    }
    &__value {
      line-height: 42px;
      margin: 0 0 0 20px;
      text-transform: uppercase;
      display: inline-block;
    }
    input[type='file'] {
      bottom: 0;
      cursor: pointer;
      font-size: 18px;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.signin-block {
  &__subtitle {
    margin: 5px 0 15px;
  }
  &__toggle {
    line-height: 30px;
  }
}

#colorbox {
  .sms_popup_code_container {
    label {
      display: block;
    }
  }
  .favorites-create-wishlist {
    margin: 2.7em 0 1.2em;
  }
  .favorites-add-to-list {
    margin: 2em 0px 1em;
  }
}

.address-item {
  &__controls {
    .link--default {
      margin-top: 5px;
    }
  }
}

.address-book {
  .address-item {
    &__info {
      .address-item__details {
        a:link,
        a:visited,
        a:hover,
        a:active {
          text-decoration: none;
        }
      }
    }
  }
}

.account-utilities {
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $color-dark-gray;
    text-decoration: none;
  }
}
