.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    .optanon-show-settings-middle {
      .optanon-show-settings {
        font-family: $chinese_font_global;
        color: $color-black !important;
        font-size: 18px;
        letter-spacing: 0;
      }
    }
  }
}
