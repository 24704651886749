.utility-nav {
  &.utility-nav-v1 {
    display: flex;
  }
}

.page-header__utils__logo {
  margin-bottom: 12px;
}

.page-branding__logo {
  font-family: $english_font;
}

.page-header {
  &__meta-nav {
    &__inner {
      .gnav-offers-block__list__item {
        a.gnav-offers-block__offer--link {
          &.font--franklin {
            font-family: $chinese_font;
          }
        }
      }
    }
  }
  &.alt-nav-mob,
  &__nav {
    .page-header__nav__supplemental {
      .gnav-util--search.gnav-util {
        .gnav-util__content__inner {
          form.gnav-search {
            &.search-form {
              input[type='search'] {
                font-family: $chinese_font;
              }
            }
            .gnav-search--main-content {
              .gnav-search__content {
                font-family: $chinese_font;
                .menuref {
                  &__title,
                  &__content {
                    font-family: $chinese_font;
                    a,
                    p,
                    span {
                      font-family: $chinese_font;
                    }
                  }
                }
              }
              .gnav-search__suggestions-wrapper {
                .product-heading__subline {
                  font-family: $chinese_font;
                  font-size: 15px;
                  margin: 10px 0;
                }
                .product-brief {
                  &__subline {
                    font-family: $chinese_font;
                    font-size: 15px;
                    margin: 10px 0;
                  }
                  &__flag {
                    font-family: $chinese_font;
                  }
                  &__title__link {
                    font-family: $english_font;
                  }
                  &__subtitle__link {
                    font-family: $english_font;
                  }
                }
                .product-grid__content {
                  .product-brief__flag {
                    font-family: $chinese_font;
                  }
                }
              }
            }
          }
          .gnav-dropdown__footer__item {
            font-family: $chinese_font;
          }
        }
      }
    }
  }
  &__utils {
    .gnav-util {
      &--cart {
        .cart-block {
          &__header__item-count,
          &__footer__item-count,
          &__shipping__title {
            font-family: $chinese_font;
          }
        }
        .cart-item__qty {
          font-family: $chinese_font;
        }
      }
      &__content {
        .cart-block {
          &__header__item-count {
            display: none;
          }
        }
      }
    }
  }
  &__nav__main {
    .gnav-menu {
      &-label,
      &-link__item {
        font-family: $chinese_font;
      }
      &-item {
        &__link,
        &__title-wrap {
          font-family: $chinese_font;
        }
      }
    }
  }
}

.utility-nav {
  &__item {
    font-family: $chinese_font;
    .utility-nav__menu-items {
      font-family: $chinese_font;
      a {
        font-family: $chinese_font;
      }
    }
  }
  .menu__link--lvl-1 {
    font-family: $chinese_font;
  }
  &__account-button {
    a {
      font-family: $chinese_font;
    }
  }
  &-mob__item {
    span,
    a {
      font-family: $chinese_font;
    }
  }
}

.footer-section-primary {
  &__title,
  .menu__link--lvl-1 {
    font-family: $chinese_font;
  }
}

.footer-links {
  &__item {
    font-family: $chinese_font;
    a {
      font-family: $chinese_font;
    }
  }
  &__copyright {
    font-family: $english_font;
  }
}

.page-content .tabbed-block__tab {
  font-family: $chinese_font;
}

.page-main {
  .hero-banner {
    &__text {
      .hero-banner__title {
        font-family: $chinese_font;
      }
    }
  }
  .hero-image-large {
    &__header,
    &__subheader,
    &__content-extra {
      font-family: $chinese_font;
    }
  }
  .flex-tout {
    &__eyebrow,
    &__title,
    &__subhead {
      font-family: $chinese_font;
    }
  }
  .product-brief {
    &__flag,
    &__cta,
    &__subline,
    &__subline-link,
    &__concern:nth-child(1) {
      font-family: $chinese_font;
      width: 100%;
    }
    &__title,
    &__title-link,
    &__title__link {
      font-family: $english_font;
    }
    &__subtitle,
    &__subtitle-link {
      font-family: $chinese_font;
      font-size: 21px;
    }
    &__cta-spp-link a {
      font-family: $chinese_font;
    }
    .product-brief__cta-add-to-bag {
      .product-add-to-bag,
      .product-out-of-stock {
        font-family: $chinese_font;
      }
    }
    .product-brief__subline {
      font-size: 21px;
      margin: 10px 0;
    }
    .product-brief__price {
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
    &__shades-wrapper {
      @include breakpoint($medium-up) {
        height: 34px;
      }
    }
    button.slick-prev.slick-arrow {
      @include breakpoint($medium-up) {
        top: 0;
      }
    }
    button.slick-next.slick-arrow {
      @include breakpoint($medium-up) {
        top: 0;
      }
    }
  }
  .product-quickshop {
    .product-heading {
      &__name {
        a {
          font-family: $english_font;
        }
      }
      &__subheading {
        font-family: $english_font;
        font-size: 24px;
      }
      &__subline {
        font-family: $chinese_font;
      }
    }
    &__description {
      font-family: $chinese_font;
      span,
      strong,
      div {
        font-family: $chinese_font;
      }
    }
  }
  a.button {
    font-family: $chinese_font;
  }
  .product-quote {
    &__read-more {
      font-family: $chinese_font;
    }
  }
}

.spp-page {
  .product-full {
    .product-full__breadcrumb {
      .product-breadcrumb__link--category {
        font-family: $chinese_font;
        line-height: 15px;
      }
      .product-breadcrumb__link--product {
        font-family: $english_font;
      }
    }
    .product-full__name {
      font-family: $english_font;
    }
    .product-full__subline,
    .product-offer,
    .product-offer__header,
    .product-offer__text,
    .product-full__overview div {
      font-family: $chinese_font;
    }
    .product-full__add-to-bag {
      .product-add-to-bag {
        font-family: $chinese_font;
      }
    }
    .product-full__overview-trigger {
      &.current {
        font-family: $chinese_font;
      }
    }
    .product-full__add-to-favorites {
      font-family: $chinese_font;
    }
    .product-full__tabbed-content b {
      font-family: $chinese_font;
    }
    .spp-sticky-add-to-bag {
      .product-add-to-bag {
        font-family: $chinese_font;
      }
      &__description {
        .spp-sticky-add-to-bag__subline {
          font-family: $chinese_font;
        }
      }
    }
    .product__inventory-status {
      margin-top: 20px;
    }
  }
  &.spp__container {
    .spp_customer_reviews {
      #pr-questiondisplay {
        .pr-qa-display-ask-question {
          .pr-accessible-btn {
            font-family: $chinese_font;
          }
        }
      }
    }
  }
}

.product-badge__text {
  font-family: $chinese_font;
}

.responsive-tabs {
  .resp-tabs-list {
    .resp-tab-item {
      font-family: $chinese_font;
    }
  }
}

.content-header__title {
  font-family: $chinese_font;
}

.sign-in-page .account-page__section--heading {
  font-family: $chinese_font;
}

.regimen-finder {
  .quiz-landing-page-formatter {
    &__results-footer {
      a.retake-quiz {
        @include breakpoint($medium-up) {
          width: 12%;
          font-size: 17px;
        }
      }
      a.product__button--add-to-bag {
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
      }
    }
  }
}

.custom-text.mantle-custom-text.format--default.style--bare {
  font-size: 15px;
  @include breakpoint($medium-up) {
    font-size: 24px;
  }
}

#wo_chatbox {
  #wo_online_image {
    right: auto;
  }
}

#udesk_btn {
  a {
    transition: all 0.2s ease-in-out;
  }
  a:hover {
    /* stylelint-disable */
    bottom: 5px !important;
    right: 13px !important;
    /* stylelint-enable */
    transform: scale(1.2);
  }
}
