.margin_top_bottom {
  margin: 0.8em 0;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.new-address {
  .form_element.title {
    .errorLabel {
      color: $color-light-red;
    }
  }
}

input[name='PHONE1'] {
  &.checkedEmpty {
    border-color: $color-red;
  }
}

.adpl {
  input[type='tel'] {
    & + label.errorLabel:before {
      color: $color-light-red;
    }
  }
  input[type='text'].error {
    border-color: $color-red;
  }
}

.section-store-locator {
  .store-search-controls {
    .form_element {
      &.city_container,
      &.door_container {
        margin-bottom: 5%;
      }
    }
  }
  .store-locator__map-container {
    .BMap_pop {
      .store-locator__tooltip {
        border: medium none;
        .store-locator__tooltip-close,
        .add-to-favorites {
          display: none;
        }
      }
    }
    img {
      max-width: initial;
    }
  }
}

.sticky-chat {
  height: 96px;
}

.page-footer {
  .page-footer__secondary {
    .integrated-prechat-container {
      z-index: 999;
    }
  }
}

.search_results__summary {
  .search-again {
    .form-type-textfield {
      .search-term {
        width: 280px;
      }
    }
  }
}

.site-offline {
  &__welcome {
    font-size: 150px;
    line-height: 1em;
  }
  &__help:after {
    display: none;
  }
}
