$chinese_font_global: 'Microsoft YaHei', 'PingFang', sans-serif;
$english_font_global: $font--futura-book;

$english_font_1: $font--futura-medium;
$english_font_2: $font--futura-demi;
$english_font_3: $font--futura-book;
$english_font_4: $font--futura-book;

$chinese_font: 'Microsoft YaHei', 'PingFang', sans-serif;
$english_font: $font--futura-book;

body,
td,
th,
tt,
p,
a,
div,
span,
#pg_wrapper select,
h1,
h2,
h3,
h4,
h5,
h6,
.navigation,
.link,
a.link button,
input,
optgroup,
select,
textarea,
#footer,
form label,
form,
select,
a.button,
.h2,
.h3,
strong,
label {
  font-family: $chinese_font_global;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='password'],
input[type='submit'],
input[type='button'] {
  font-family: $chinese_font_global;
}

input[type='text']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='tel']::-moz-placeholder,
input[type='search']::-moz-placeholder,
input[type='password']::-moz-placeholder,
input[type='submit']::-moz-placeholder,
input[type='button']::-moz-placeholder,
textarea::-moz-placeholder {
  font-family: $chinese_font_global;
}
/* homepage */
.page-branding__logo {
  font-family: $english_font_global;
}

.page-header {
  &__meta-nav {
    .gnav-offers-block {
      &__headline {
        font-family: $chinese_font_global;
      }
    }
  }
  &__utils {
    .site-email-signup,
    .gnav-mini-facials-block,
    .gnav-find-store-block {
      &__title,
      &__subtitle,
      &__copy,
      &__cta,
      &__fields,
      &__submit,
      &__use-location {
        font-family: $chinese_font_global;
      }
    }
    .gnav-util {
      &--search {
        .gnav-search__content {
          .menuref__title {
            font-family: $chinese_font_global;
          }
          .menuref__content {
            .menu__link {
              font-family: $english_font_global;
            }
          }
        }
      }
      &--cart {
        .cart-block {
          &__header__item-count,
          &__footer__item-count,
          &__shipping__title {
            font-family: $chinese_font_global;
          }
        }
        .cart-item__qty {
          font-family: $chinese_font_global;
        }
      }
    }
  }
  &__nav__main {
    .menu__link {
      font-family: $chinese_font_global;
    }
    .menu--lvl-2 {
      .gnav-dropdown {
        &__content {
          .product-brief {
            &__title__link {
              font-family: $english_font_1;
            }
            &__subtitle__link {
              font-family: $english_font_2;
            }
          }
          .menuref {
            &__title {
              font-family: $chinese_font_global;
            }
            &__content {
              .menu__link {
                font-family: $chinese_font_global;
              }
            }
          }
          .basic-noderef {
            .gnav-by-collection {
              &__title,
              &-item__desc {
                font-family: $chinese_font_global;
              }
            }
          }
        }
      }
    }
  }
}

#prechat_container {
  #chat-header {
    font-family: $chinese_font_global;
  }
  .prechat-form-body {
    .require-msg,
    input,
    textarea,
    .lpbuttonproduct-msg,
    .prechat-messaging-area a {
      font-family: $chinese_font_global;
    }
  }
}

.page-header__utils,
.page-footer__primary {
  .menu {
    &__link--lvl-1,
    &__link--lvl-2 {
      font-family: $chinese_font_global;
    }
  }
}

.page-main {
  .hero-image-large {
    &__header,
    &__subheader,
    &__content-extra {
      font-family: $chinese_font_global;
    }
  }
  .flex-tout {
    &__eyebrow,
    &__title,
    &__subhead {
      font-family: $chinese_font_global;
    }
  }
  .page-content {
    .spp-page {
      .product-full {
        &__meta {
          .product-breadcrumb {
            a {
              font-family: $english_font_2;
            }
            span {
              font-family: $english_font_2;
            }
          }
        }
      }
    }
  }
  .product-brief {
    &__flag &__subtitle,
    &__cta,
    &__concern:nth-child(1) {
      font-family: $chinese_font_global;
    }
    &__title__link {
      font-family: $english_font_1;
    }
    &__subtitle__link {
      font-family: $english_font_2;
      font-size: 21px;
    }
  }
  .product-quickshop {
    .product-heading {
      &__name {
        a {
          font-family: $english_font_1;
        }
      }
      &__subheading {
        font-family: $english_font_2;
        font-size: 24px;
      }
    }
    &__description {
      font-family: $chinese_font_global;
    }
  }
}

.page-footer__secondary {
  .country-chooser {
    .language-select {
      &__link {
        font-family: $chinese_font_global;
      }
    }
  }
  .menuref {
    .menu {
      &__link--lvl-1 {
        font-family: $chinese_font_global;
      }
    }
  }
  .sticky-chat {
    &__headline {
      font-family: $chinese_font_global;
    }
  }
  .copyright {
    font-family: $english_font_3;
  }
}

.product-grid {
  &__content {
    .product-brief {
      &__flag {
        font-family: $chinese_font_global;
      }
    }
  }
}

.page-content {
  .tabbed-block {
    &__tab {
      font-family: $chinese_font_global;
    }
  }
}
/* mpp - spp */
.mpp {
  .product-brief {
    &__cta {
      font-family: $chinese_font_global;
    }
    &__title__link {
      font-family: $english_font_1;
    }
  }
  .product-quickshop {
    .product-heading {
      &__subheading {
        font-family: $english_font_2;
        font-size: 24px;
      }
    }
    &__description {
      font-family: $chinese_font_global;
    }
  }
}

.product-full {
  .product-heading {
    &__subheading {
      font-family: $english_font_2;
      font-size: 24px;
    }
  }
  .product-info {
    .product-add-to {
      &-bag,
      &-waitlist {
        font-family: $chinese_font_global;
      }
      &-favorites {
        &__icon-text {
          font-family: $chinese_font_global;
        }
      }
    }
    .product-offer {
      &__header,
      &__text {
        font-family: $chinese_font_global;
      }
    }
    .product-attributes {
      .product-attribute {
        &__label,
        &__title {
          font-family: $chinese_font_global;
        }
      }
    }
  }
}

.product-fave-confirm {
  &__header {
    font-family: $chinese_font_global;
  }
}

.mpp {
  &__content {
    .flag {
      font-family: $chinese_font_global;
    }
  }
}

.spp-page {
  .flag--medium {
    font-family: $chinese_font_global;
  }
}

.BVRRCustomRatingCount {
  .BVRRCount {
    font-family: $english_font_2;
  }
}

.BVRRDisplayContent {
  .BVRRNickname {
    font-family: $english_font_2;
  }
  .BVRRTag {
    font-family: $english_font_global;
  }
}

.BVRRQuickTakeSummary {
  .BVRRBuyAgainContainer,
  .BVRRBuyAgainSuffix {
    font-family: $chinese_font_global !important;
  }
}

.BVRRDisplayContentLinkWrite {
  a,
  a:visited {
    font-family: $chinese_font_global !important;
  }
}

.BVRRDisplayContentNoReviews {
  a,
  a:visited {
    font-family: $chinese_font_global !important;
  }
}
/* search */
.search_results {
  .product-quickshop {
    .product-heading {
      &__subheading {
        a {
          font-family: $english_font_2;
          font-size: 24px;
        }
      }
    }
    .product-sku-select {
      &__placeholder {
        font-family: $chinese_font_global;
      }
    }
  }
  &__filter_header {
    font-family: $chinese_font_global;
  }
}
/* customer service */
.cs-page {
  .content-header {
    &__title {
      font-family: $chinese_font_global;
    }
  }
  &__title {
    h1 {
      font-family: $chinese_font_global;
    }
  }
  &__navigation {
    .cs-menu {
      &__static,
      &-trigger {
        font-family: $chinese_font_global;
      }
    }
    .expando-menu-trigger {
      font-family: $chinese_font_global;
      font-size: 25px;
    }
    .cs-contacts {
      &__grid-item h3,
      &__copy {
        font-family: $chinese_font_global;
      }
    }
  }
}
/* store locator */
.store-locator {
  &__hero-heading,
  &__search-submit,
  &__responsive-tab,
  &__result-set-favorite,
  &__my-store-heading {
    font-family: $chinese_font_global;
  }
}
/* account */
.page-header {
  &__nav__supplemental {
    .signin-block {
      &__title,
      &__subtitle,
      &__toggle__copy,
      &__toggle__cta,
      &__submit,
      &__forgot-password,
      &__checkbox__text,
      .terms_privacy {
        font-family: $chinese_font_global;
      }
    }
  }
}

.account-page {
  &__section--heading,
  &__section--subheading {
    font-family: $chinese_font_global;
  }
  .account-utilities {
    &__header {
      font-family: $chinese_font_global;
    }
  }
  .orders-list {
    &__table--cell {
      font-family: $chinese_font_global;
      &.order-date,
      &.order-number a {
        font-family: $english_font_3;
      }
    }
    .product_subname {
      font-family: $english_font_2;
    }
  }
  .regimen {
    &-quiz__fieldset__legend,
    &-page__results__title {
      font-family: $chinese_font_global;
    }
    &-results-header__text {
      font-family: $chinese_font_global;
    }
  }
  .product-attribute {
    &__title {
      font-family: $chinese_font_global;
    }
  }
  a.menu__link {
    font-family: $chinese_font_global;
  }
  &.order-details-page {
    .account-products__item {
      .product_subname {
        font-family: $english_font_2;
      }
    }
    .order-totals td:nth-child(1) {
      font-family: $chinese_font_global;
    }
  }
  .product-brief {
    &__title__link {
      font-family: $english_font_1;
    }
    &__subtitle__link {
      font-family: $english_font_2;
    }
  }
  .address-book {
    &__header {
      font-family: $chinese_font_global;
    }
  }
}

.sms_popup {
  &_bind_h2 {
    font-family: $chinese_font_global;
  }
}

.favorites {
  &__instruction-step-title,
  &-confirm-delete__text,
  &-add-to-list__header {
    font-family: $chinese_font_global;
  }
}

.headline--quinary,
.address-overlay_header {
  font-family: $chinese_font_global;
}

.address-delete {
  .address-delete__header {
    font-family: $chinese_font_global;
  }
}
/* checkout */
.checkout {
  &--heading,
  &__panel--heading {
    font-family: $chinese_font_global;
  }
  #links-panel &__panel--content {
    a {
      font-family: $chinese_font_global;
    }
  }
  .recommended-item {
    &__product-info {
      &__name__link {
        font-family: $english_font_4;
      }
      &__subname__link {
        font-family: $english_font_2;
      }
    }
    .regional_subname {
      .recommended-item {
        &__product-info {
          &__subname__link {
            font-family: $chinese_font_global;
          }
        }
      }
    }
  }
  &-order-status {
    .label {
      font-family: $chinese_font_global;
    }
  }
  .cart-items {
    &__header--item {
      font-family: $chinese_font_global;
    }
    &__item {
      &--product {
        &-name {
          a {
            font-family: $english_font_1;
          }
        }
        &-subname {
          font-family: $english_font_2;
          a {
            font-family: $english_font_2;
          }
        }
      }
      &--size {
        font-family: $english_font_2;
      }
      &.sample {
        .price,
        .total {
          font-family: $chinese_font_global;
        }
      }
    }
    &__header {
      .label {
        font-family: $chinese_font_global;
      }
    }
  }
  .samples-panel {
    .checkout {
      &__panel--heading {
        font-family: $english_font_2;
      }
    }
    .product-brief {
      &__subtitle {
        font-family: $english_font_2;
      }
      &__subline {
        font-family: $chinese_font_global;
      }
    }
    .sample-select-button {
      font-family: $chinese_font_global;
    }
  }
}

.spp_customer_reviews {
  display: none;
}
